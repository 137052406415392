@import-normalize;

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: bold;
  font-display: block;
}

@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat-Bold"),
    url("./fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
  font-display: block;
}

* {
  box-sizing: border-box;
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
input[type="text"],
input::placeholder,
a {
  font-family: "Montserrat", Helvetica, sans-serif;
  margin: 0;
}
body {
  margin: 0;
  font-family: "Montserrat", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  margin: 1px;
  padding: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* TESTING DND */

.drag-n-drop {
  padding: 0.5rem;
  gap: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.dnd-group {
  background-color: #49505e;
  border-radius: 5px;
  padding: 0.5rem;
  display: flex;
  width: 100%;
  gap: 10px;
  height: 120px;
}
.dnd-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  background-color: white;
  border-radius: 5px;
  color: #282c34;
  font-weight: bold;
  width: 100px;
  flex-direction: column;
}
.dnd-item:not(:last-of-type) {
  margin-bottom: 0.5rem;
}
.dnd-item * {
  margin: 0;
  font-size: 1.2rem;
}
.dnd-group-title {
  text-align: left;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
}
.current {
  background-color: #282c34;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 40vh;
  color: #dddddd;
  font-size: 24px;
}

/* multi range Slider test */
.range-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}

.slider {
  position: relative;
  width: 200px;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
}

.slider__track {
  background-color: #ced4da;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: #00ffed;
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: #dee2e6;
  font-size: 12px;
  margin-top: -5px;
}

.slider__left-value {
  left: -40px;
}

.slider__right-value {
  right: -40px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 200px;
  outline: none;
}

.thumb--zindex-3 {
  z-index: 3;
}

.thumb--zindex-4 {
  z-index: 4;
}

.thumb--zindex-5 {
  z-index: 5;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #00ffed;
  border: none;
  border-radius: 2px;
  filter: drop-shadow(0 0 4px black);
  cursor: pointer;
  transform: skewX(-14deg);
  height: 18px;
  width: 14px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #00ffed;
  border: none;
  border-radius: 2px;

  filter: drop-shadow(0 0 4px black);
  cursor: pointer;
  height: 18px;
  width: 10px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}
