
/* Contact Page Start */
.contactPage{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(65vh - 3em);

    input[type="text"], input[type="email"], input[type="password"], textarea {
        border:none;
        width: 100%;
        height: 100%;
        background-color: transparent;
        border:none;
        font-family:"Montserrat";
        font-size: 20px;
        color: #DDD;
        outline:none;
        resize:none;
        padding-left: 10px;
     }
     input[type="text"],input[type="email"],input[type="password"]{
        margin: 5px 0;
        height: 30px;
        border-bottom: #FEFEFE thin solid;
        padding-bottom: 10px;
       
     }
     textarea{
        border: #FEFEFE thin solid;
        padding: 10px;
        border-radius: 4px;
        height:140px;
     }

    h1{
        color:#FEFEFE;
    }

    .contactForm{
        width: 500px;
        display: flex;
        flex-direction: column;
        height: auto;
        gap: 10px;
        background: #272b2bf8;
        padding: 20px;

        button{
            height: 40px;
            font-size: 20px;
            text-transform: uppercase;
            font-family: 'Montserrat';
            color: #363b3b;
        }
    }
}
/* Contact Page End */